<template>
  <div class="m-grid p-client-list" style="overflow-y: auto">
    <div class="m-grid-search">
      <!-- <znl-input
        ref="noOrder"
        v-model="searchData.isNoUse"
        :select-options="noOrderData.NoOrderList"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="未下单客户"
        width="150"
        @change="onSelect('no')"
      >
      </znl-input> -->
     <!-- <el-cascader :options="options" clearable></el-cascader> -->


      <el-select
          v-model="selectdata.isNoUse"
          @change="selecttype"
          filterable
          remote
          clearable
          placeholder="未下单客户"
        >
          <el-option
            v-for="item in options"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
    <span>-</span>
        <el-select
          v-model="selectdata.isNoUseinfo"
         @change="selecttypeinfo"
          filterable
          remote
         clearable
          placeholder="未下单客户详情"
        >
          <el-option
            v-for="item in Nofollowoptions"
            ref="nofollow"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>




      <!-- <znl-input
        ref="todayOrder"
        v-model="searchData.isToDayUse"
        :placeholder="`今天未下单客户（${noOrderData.Count}）`"
        :select-options="noOrderData.TodayNoOrderList"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        width="150"
        @change="onSelect('today')"
      ></znl-input>
      <znl-input
        ref="nofollow"
        v-model="searchData.isNoFollow"
        :select-options="noFollowData.NoFollowList"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="未询价/跟进客户"
        width="150"
        @change="onSelect('follow')"
      >
      </znl-input> -->

      <el-button class="m-grid-btn" style="margin-left:50px"   type="primary" @click="onSearch()"
        >查询
      </el-button>
    </div>
    <div class="m-grid-search">
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:add'"
        type="primary"
        @click="onAddClient"
        >新增</el-button
      >
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:edit'"
        type="primary"
        @click="onEditClient"
        >修改</el-button
      >
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:delete'"
        type="primary"
        @click="onDeleteClient"
        >删除</el-button
      >
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:add_common'"
        type="primary"
        @click="onAddCommon"
        >加入公海</el-button
      >
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:merge_client'"
        type="primary"
        @click="onMergeClient"
        >合并客户
      </el-button>
      <el-button
        class="m-grid-btn"
        v-can="'client_manage_take:change_type'"
        type="primary"
        @click="onChangeType"
        >更改客户类型</el-button
      >
    </div>
    <section class="m-grid-list p-client-list-bd">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.limit"
        :selection="true"
        @onColumnMoved="onColumnMoved"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
      >
      </grid>
    </section>

    <merge-client-view
      ref="mergeClient"
      @onSearch="onSearch"
    ></merge-client-view>
    <change-type-view
      ref="changeTypeView"
      :clientTypeList="clientTypeList"
      @onSearch="onSearch"
    ></change-type-view>
    <add-common-view ref="addCommonView" @onSearch="onSearch"></add-common-view>
  </div>
</template>

<script>
import { columns } from "./Modules/List/config";
import {
  deleteClient,
  getClientList,
  GetNoOrderList,
  GetNoFollowList,
} from "../../api/client";
import MergeClientView from "./Modules/List/MergeClientView";
import ChangeTypeView from "./Modules/List/ChangeTypeView";

import AddCommonView from "./Modules/AddCommonView";

import { getClientType,selectFollow,selectOnOrder,selectInquired,selectOnFollow,selectNoOrder } from "../../api/client";
import { userColumnMixin } from "@/components/Mixins/UserColumnMixin"
const TableName = "customer-pd-list"

export default {
  name: "clientTake",
  mixins: [userColumnMixin],
  components: {
    MergeClientView,
    ChangeTypeView,
    AddCommonView,
  },
  computed: {
    isAllBtn() {
      const accessPoint = [
        "client_manage_list:add",
        "client_manage_list:delete",
        "client_manage_list:add_common",
        "client_manage_list:merge_client",
        "client_manage_list:change_type",
      ];
      return accessPoint.some((item) => this.can(item));
    },
  },
  data() {
    return {
      columns: columns,
      itemSource: [],
      FirstType:-1,
      LastType:-1,
      pagination: {
        page: 1,
        limit: 20,
        total: 40,
      },
      noOrderData: {
        NoOrderList: [],
        TodayNoOrderList: [],
        Count: 0,
      },
      noFollowData: {
        NoFollowList: [],
        Count: 0,
      },
      searchData: {
        isToDayUse: "",
        isNoUse: "",
        isNoFollow: "",
      },
      selectdata:{
        isNoUseinfo:"",
        isNoUse:""
      },
      clientTypeList: [],
       options: [
         {
           key:1,
           value:"未下单客户"
         },
          {
           key:2,
           value:"未跟进客户"
         },
          {
           key:3,
           value:"未询价客户"
         },
         {
           key:4,
           value:"待跟进客户"
         },
          {
           key:5,
           value:"待下单客户"
         }
       ],
       Nofollowoptions:[]
    };
  },
  methods: {
    onInit() {
      this.onSelectOrder();
      this.setClientType();
    },
    // 获取选择列表数据
    // 客户类型数量和表头满分
    async getClientType() {
      return await getClientType();
    },
    // 处理搜索参数
    setParams() {
      let params = {};
      params.Id =
        this.searchData.isToDayUse ||
        this.searchData.isNoUse ||
        this.searchData.isNoFollow;
      return params;
    },
    // 处理选择列表数据
    // 客户类型
    async setClientType() {
      const data = await this.getClientType();
      const { CustomerTypes } = data;
      let clientTotal = 0;
      CustomerTypes.forEach((item) => {
        clientTotal += item["Count"];
      });
      this.clientTypeList = CustomerTypes.map((item) =>
        Object.assign(
          {},
          {
            name: item["CustomerTypeName"],
            value: item["Count"],
            type: item["CustomerType"],
          }
        )
      );
      this.clientTypeList.unshift({
        name: "全部客户",
        value: clientTotal,
        type: "",
      });

      // 初始化
      this.selectClientType = this.clientTypeList[0];
    },
    //获取未下单客户
    async onSelectOrder() {
      let noOrder = await GetNoOrderList();
      let noFollow = await GetNoFollowList();

      if (noOrder) {
        this.noOrderData.Count = noOrder.Count;
        this.noOrderData.NoOrderList = noOrder.NoOrderList.map((item) => {
          return {
            key: item.CompanyID,
            value: item.CompanyName,
          };
        });
        this.noOrderData.TodayNoOrderList = noOrder.TodayNoOrderList.map(
          (item) => {
            return {
              key: item.CompanyID,
              value: item.CompanyName,
            };
          }
        );
      }
      if (noFollow) {
        this.noFollowData.Count = noFollow.Count;
        this.noFollowData.NoFollowList = noFollow.NoFollowList.map((item) => {
          return {
            key: item.CompanyID,
            value: item.CompanyName,
          };
        });
      }
    },
    async selecttype(data){
      this.FirstType=data
       this.Nofollowoptions=[]
       if(data==0)
       {
         this.selectdata.isNoUseinfo="";
        this.$refs.nofollow.clear();

       }
      // 
      if(data==2)
      {
          let ret = await selectFollow()
          console.log(ret)
          let Week="("+ret.WeekFollow+")"
          let Fiveth="("+ret.FivthFollow+")"
          let Three="("+ret.ThreeFollow+")"
          let Six="("+ret.SixFollow+")"
          this.Nofollowoptions=[
            {
             key:1,
             value:"7天未跟进"+Week
            },
           {
             key:2,
             value:"15天未跟进"+Fiveth
            },
            {
            key:3,
            value:"30天未跟进"+Three
           },
            {
             key:4,
             value:"60天未跟进"+Six
          }
          ]
          console.log(this.Nofollowoptions)
      }
      if(data==5)
      {
          let ret = await selectOnOrder()
          console.log(ret)
          let ToDAY="("+ret.TodayOrder+")"
          let Tomorrow="("+ret.TomorrowOrder+")"
          let Week="("+ret.WeekOrder+")"
          let Month="("+ret.MonthOrder+")"
          this.Nofollowoptions=[
            {
             key:1,
             value:"今天待下单"+ToDAY
            },
           {
             key:2,
             value:"明天待下单"+Tomorrow
            },
            {
            key:3,
            value:"本周待下单"+Week
           },
            {
             key:4,
             value:"本月待下单  "+Month
          }
          ]
          console.log(this.Nofollowoptions)
      }
      if(data==1)
      {
          let ret = await selectNoOrder()
          console.log(ret)
          let One="("+ret.OneOrder+")"
          let Two="("+ret.TwoOrder+")"
          let Month="("+ret.MonthOrder+")"
          let Six="("+ret.SixOrder+")"

          this.Nofollowoptions=[
            {
             key:1,
             value:"10天未下单"+One
            },
           {
             key:2,
             value:"20天未下单"+Two
            },
            {
            key:3,
            value:"30天未下单"+Month
           },
            {
             key:4,
             value:"60天未下单"+Six
          }
          ]
          console.log(this.Nofollowoptions)
      }
      if(data==3)
      {
        let ret = await selectInquired()
          console.log(ret)
          let Week="("+ret.WeekFollow+")"
          let Fiveth="("+ret.FivthFollow+")"
          let Three="("+ret.ThreeFollow+")"
          let Six="("+ret.SixFollow+")"
          this.Nofollowoptions=[
            {
             key:1,
             value:"7天未询价"+Week
            },
           {
             key:2,
             value:"15天未询价"+Fiveth
            },
            {
            key:3,
            value:"30天未询价"+Three
           },
            {
             key:4,
             value:"60天未询价"+Six
          }
          ]
          console.log(this.Nofollowoptions)
      }
      if(data==4)
      {
         let ret = await selectOnFollow()
          console.log(ret)
          let ToDAY="("+ret.TodayOrder+")"
          let Tomorrow="("+ret.TomorrowOrder+")"
          let Week="("+ret.WeekOrder+")"
          let Month="("+ret.MonthOrder+")"
          this.Nofollowoptions=[
            {
             key:1,
             value:"今天待跟进"+ToDAY
            },
           {
             key:2,
             value:"明天待跟进"+Tomorrow
            },
            {
            key:3,
            value:"本周待跟进"+Week
           },
            {
             key:4,
             value:"本月待跟进"+Month
          }
          ]
          console.log(this.Nofollowoptions)
      }
    },
    selecttypeinfo(data)
    {
        this.LastType=data;
    },
    onSelect(val) {
      if (val === "no") {
        this.$refs.todayOrder.clear();
        this.searchData.isToDayUse = "";
        this.searchData.isNoFollow = "";

      } else if (val === "follow") {
        this.$refs.todayOrder.clear();
        this.$refs.noOrder.clear();
        this.searchData.isNoUse = "";
        this.searchData.isToDayUse = "";
      } else {
        this.$refs.noOrder.clear();
        this.$refs.nofollow.clear();
        this.searchData.isNoUse = "";
        this.searchData.isNoFollow = "";
      }
    },
    async onSearch(page = 1,  a) {
      const { pagination } = this;
      let sort={
        title:this.selectTitle,
        state:this.selectSort
      };
      
      let sendData = {
        Page: page,
        Limit: pagination.limit,
        SortDate: sort,
         title:a,
        FirstType:this.FirstType,
        LastType:this.LastType
      };
      sendData = Object.assign(sendData, this.setParams());

      const data = await getClientList(sendData);

      if (a == "wxj" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoInquiryDaysP > arr[j + 1].NoInquiryDaysP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      }
      if (a == "wxj" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoInquiryDaysP < arr[j + 1].NoInquiryDaysP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      }
      if (a == "wxd" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoOrderDaysP > arr[j + 1].NoOrderDaysP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      }
      if (a == "wxd" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoOrderDaysP < arr[j + 1].NoOrderDaysP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      }
      if (a == "ghdjs" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].SeaPoolCountDown > arr[j + 1].SeaPoolCountDown) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      }
      if (a == "ghdjs" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].SeaPoolCountDown < arr[j + 1].SeaPoolCountDown) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        console.log(arr);
        pagination.page = data.Page;
        pagination.total = data.Total;
        let list = arr;
        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });
        this.itemSource = list;
      } else {
        pagination.page = data.Page;
        pagination.total = data.Total;

        let list = data.Items;

        list.forEach((item, index) => {
          item.rowIndex = index + 1;
        });

        this.itemSource = list;
      }
    },
    // 操作
    // 新增
    onAddClient() {
      this.$router.push({
        name: "newClient",
      });
    },
    // 修改
    onEditClient() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;
      let err = "";
      if (length === 0) {
        err = "请选择一条数据";
      }
      if (length > 1) {
        err = "一次只能修改一条数据";
      }

      if (err) {
        this.$message.warning(err);
        return false;
      }

      const data = selectRow[0];
      this.$router.push({
        name: "editClient",
        query: {
          id: data.CompanyID,
        },
      });
    },
    onDeleteClient() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;
      let err = "";
      if (length === 0) {
        err = "请选择一条数据";
      }

      if (err) {
        this.$message.warning(err);
        return false;
      }

      const ids = selectRow.map((item) => item.CompanyID);
      this.$confirm("此操作将永久删除该客户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteClient(ids);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 加入公海
    onAddCommon() {
      const selectRow = this.$refs.list.getSelectedRows();
      if (selectRow.length === 0) {
        this.$message.warning("请选择一条数据");
      }
      const data = selectRow.map((item, index) => {
        return {
          indexRow: index + 1,
          id: item.CompanyID,
          clientName: item.CompanyName,
          isSelect: true,
        };
      });

      this.$refs.addCommonView.onOpen(data);
    },
    onProtect() {},
    onAllocation() {},
    onTransfer() {},
    onRinse() {},
    onMergeClient() {
      const selectRows = this.$refs.list.getSelectedRows();
      if (selectRows.length <= 1) {
        this.$message.warning("请至少选择两条数据");
        return false;
      }
      const ids = selectRows.map((item) => item["CompanyID"]);

      this.$router.push({
        name: "mergeClient",
        query: {
          ids: ids,
        },
      });
    },
    onChangeType() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;

      let err = "";
      if (length === 0) {
        err = "请选择一条数据";
      }

      if (err) {
        this.$message.warning(err);
        return false;
      }

      const data = selectRow;
      this.$refs.changeTypeView.onOpen(data);
    },
    async onColumnMoved(col) {
      if (col.column) {
        const { field, ColumnCode } = col.column.colDef
        const columns = this.$refs.list.$refs.table.columnDefs
        const localColumns = columns.map(a => { return { ...a, ColumnCode: a.field, ColumnName: a.headerName } })
        const newColumns = [...localColumns]
        const index = newColumns.findIndex(a => a.ColumnCode === (field || ColumnCode))
        if (index >= 0) {
          const _col = newColumns[index]
          newColumns.splice(index, 1)
          newColumns.splice(col.toIndex,0, _col)
          await this.onUserColumnMoved(newColumns, TableName)
        }
      }
    },
    // 初始化表格
    async initColumns() {
      const self = this;
      const RateColumn = columns.find((item) => item.field === "EmergencyLevel");
      const LastFolowUpTime = columns.find((item) => item.field === "LastFolowUpTime");
      const LastOrderTime = columns.find((item) => item.field === "LastOrderTime");
      const LastInquireTime = columns.find((item) => item.field === "LastInquireTime");
      const NoOrderDaysP = columns.find((item) => item.field === "NoOrderDaysP");
      const NoInquiryDaysP = columns.find((item) => item.field === "NoInquiryDaysP");
      const NoOrderCountdownP = columns.find((item) => item.field === "NoOrderCountdownP");
      const NoInquiredCountdownP = columns.find((item) => item.field === "NoInquiredCountdownP");
      const NofollowCountdownP = columns.find((item) => item.field === "NofollowCountdownP");
      const CreatedTime = columns.find((item) => item.field === "CreatedTime");

      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
        
      // 创建时间
      if (CreatedTime) {
        CreatedTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 跟进时间
      if (LastFolowUpTime) {
        LastFolowUpTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 最近下单时间
      if (LastOrderTime) {
        LastOrderTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

       // 最后询价时间
      if(LastInquireTime){
         LastInquireTime.headerComponentParams = {
            click:(data)=>{
            let page=1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page)
            }
         }
      }
      // 未下单公海倒计时
      if (NoOrderCountdownP) {
        NoOrderCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未跟进公海倒计时
      if (NofollowCountdownP) {
        NofollowCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未询价公海倒计时
      if (NoInquiredCountdownP) {
        NoInquiredCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }
      // 未询价天数
      if (NoInquiryDaysP) {
        NoInquiryDaysP.headerComponentParams = {
          click: (data) => {
            console.log(data);
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未下单天数
      if (NoOrderDaysP) {
        NoOrderDaysP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }
      }
      const newColumns = await this.sort(columns, TableName)
      if(this.$refs.list) {
        this.$refs.list.changeColumns(newColumns);
      }
    },

    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const detailBtn = {
        text: "详情",
        click() {
          self.onOpenDetail(data);
        },
      };
      const editBtn = {
        text: "写跟进",
        click() {
          self.onOpenEdit(data);
        },
      };

      buttons.push(detailBtn);

      if (this.can("client_manage_take:write_follow")) {
        buttons.push(editBtn);
      }
      return buttons;
    },
    onOpenDetail(data) {
      const id = data.CompanyID;
      this.$router.push({
        name: "detailClient",
        query: {
          id: id,
        },
      });
    },
    async onOpenEdit(data) {
      const id = data.CompanyID;
      this.$router.push({
        name: "followClient",
        query: {
          id: id,
        },
      });
    },
  },
  created() {
    this.onInit();
    this.onSearch();
  },
  async mounted() {
    await this.initColumns();
  },
};
</script>

<style lang="scss">
@import "./style/listView";
</style>
